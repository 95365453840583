<template>
  <div class="dashboard" data-t="dashboard">
    <div class="payments">
      <PaymentShortcut
        data-t="deposit-shortcut"
        type="deposit"
        :label="t('dashboard.depositRequests')"
        link="/personal/transactions?tab=deposit"
        :counter="depositCounter"
      />
      <PaymentShortcut
        data-t="withdrawal-shortcut"
        type="withdrawal"
        :label="t('dashboard.withdrawalRequests')"
        link="/personal/transactions?tab=withdrawal"
        :counter="withdrawalCounter"
      />
    </div>
    <div class="profile">
      <StSidebarItem
        :level="0"
        :label="t('dashboard.profile')"
        icon="user-solid"
        link="/personal/profile"
        data-t="profile-link"
        ignore-active-state
      />
      <StSidebarItem
        :level="0"
        :label="t('dashboard.wallet')"
        icon="wallet-solid"
        link="/personal/wallet"
        data-t="wallet-link"
        ignore-active-state
      />
      <StSidebarItem
        :level="0"
        :label="t('dashboard.transactions')"
        icon="transactions"
        link="/personal/transactions"
        data-t="transactions-link"
        ignore-active-state
      />
      <StSidebarItem
        :level="0"
        :label="t('dashboard.verification')"
        icon="id-card-clip-solid"
        link="/personal/kyc"
        data-t="kyc-link"
        ignore-active-state
        :badge="kycLevelName"
      />
      <StSidebarItem
        :level="0"
        :label="t('dashboard.myBets')"
        icon="ticket-solid"
        link="/personal/bets"
        :counter="betCounter"
        data-t="bets-link"
        ignore-active-state
      />
      <StSidebarItem
        :level="0"
        :label="t('dashboard.bonuses')"
        :counter="bonusesCount"
        icon="gift-solid"
        link="/bonuses"
        data-t="bonuses-link"
        ignore-active-state
      />
    </div>
    <div class="exit">
      <StSidebarItem
        :level="0"
        :label="t('dashboard.logout')"
        icon="logout"
        data-t="logout-button"
        @click="logout"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProfileDeps } from '../../useDeps'
import PaymentShortcut from './parts/PaymentShortcut.vue'
import { useDashboardCountersStore } from './useDashboardCountersStore'

const { t } = useI18n()

const { depositCounter, withdrawalCounter } = storeToRefs(
  useDashboardCountersStore(),
)

const { bonusesCount, betCounter, kycLevelName, logout } = useProfileDeps()
</script>

<style scoped>
.dashboard {
  width: 360px;
  padding: var(--spacing-050);
  background-color: var(--background-primary);
  border-radius: var(--border-radius-075);
}

.payments {
  display: flex;
  gap: var(--spacing-050);
}

.profile {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-025);
  margin-top: var(--spacing-050);
}

.exit {
  margin-top: var(--spacing-050);
  padding-top: var(--spacing-050);
  border-top: 1px solid var(--border-secondary);
}
</style>
