<template>
  <div class="user-actions" data-t="user-actions">
    <StTooltip
      v-model="isOpenBonuses"
      placement="bottom-end"
      trigger="custom"
      disable-teleport
      background="container-primary"
      styles="border-radius: var(--border-radius-150)"
    >
      <template #activator>
        <HeaderBonuses :counter="bonusesCount" @click="handleBonusesClick" />
      </template>
      <BonusesWidget @show-all="isOpenBonuses = false" />
    </StTooltip>

    <div v-if="activeAccount" class="tooltip-wrapper">
      <StTooltip
        v-model="isOpenAccounts"
        class="tooltip-balance"
        trigger="click"
        placement="bottom-end"
        background="container-secondary"
        disable-teleport
      >
        <template #activator>
          <div class="tooltip-activator" data-t="tooltip-wallet-chp6">
            <StIcon :name="activeAccount.icon" size="20" />
            <div class="text-wrapper">
              <div class="text" data-t="account-amount-8itx">
                {{ text }}
              </div>
              <BonusBalance
                v-if="+activeAccount.bonusAccountAmount > 0"
                class="bonus-balance"
                :amount="bonusBalance"
                no-title
              />
            </div>
            <StIcon
              name="chevron-down"
              :class="{ active: isOpenAccounts }"
              class="icon"
              size="16"
            />
          </div>
        </template>
        <div class="tooltip-content">
          <UserAccount type="small" @click="isOpenAccounts = false" />
        </div>
      </StTooltip>
    </div>

    <StButton
      key="refill"
      class="refill-button"
      :label="t('header.refill')"
      type="secondary"
      replace
      :to="{ query: { ...route.query, modal: 'payments', tab: 'deposit' } }"
      data-t="refill-button-cnr5"
    />
    <div class="flex-wrapper">
      <StTooltip
        v-model="isOpenNotifications"
        placement="bottom-end"
        trigger="click"
        disable-teleport
        background="container-base"
      >
        <template #activator>
          <div class="notification-activator">
            <StButton type="gray" icon="bell-solid" />
            <div class="notifications-counter">
              <StCounter
                v-if="activeNotificationsCounter"
                :live="false"
                bg-color="orange"
                :label="activeNotificationsCounter"
              />
            </div>
          </div>
        </template>
        <NotificationsList @click.stop />
      </StTooltip>
      <StTooltip
        v-model="isOpenDashboard"
        placement="bottom-end"
        trigger="click"
        disable-teleport
        background="container-primary"
      >
        <template #activator>
          <StButton
            type="gray"
            icon="user-solid"
            data-t="profile-button-7ck1"
          />
        </template>
        <UserDashboard />
      </StTooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAccountsStore } from '@st/payments/stores/useAccountsStore'
import UserDashboard from '@st/profile/components/UserDashboard/UserDashboard.vue'
import NotificationsList from '@st/profile/components/NotificationsList/NotificationsList.vue'
import { useNotificationsStore } from '@st/profile/stores/useNotificationsStore'
import { useBonusesCountStore } from '@st/bonuses/stores/useBonusesCountStore'
import BonusesWidget from '@st/bonuses/components/BonusesWidget/BonusesWidget.vue'
import BonusBalance from '@st/payments/components/BonusBalance/BonusBalance.vue'
import HeaderBonuses from './HeaderBonuses/HeaderBonuses.vue'

const { t } = useI18n()
const accountsStore = useAccountsStore()
const { activeAccount } = storeToRefs(accountsStore)

const { format } = useCryptoFormatter()

const isOpenDashboard = ref(false)

const route = useRoute()

const isOpenAccounts = ref(false)

const isOpenNotifications = ref(false)
const notificationsStore = useNotificationsStore()
const { activeNotificationsCounter } = storeToRefs(notificationsStore)

const text = computed(() =>
  route.meta.inGame ? t('header.inGame') : format(activeAccount.value.balance),
)

const bonusBalance = computed(() =>
  format(activeAccount.value.bonusAccountAmount),
)

const isOpenBonuses = ref(false)
const { bonusesCount } = storeToRefs(useBonusesCountStore())

function handleBonusesClick() {
  isOpenBonuses.value = !isOpenBonuses.value
}

watch(
  () => route.fullPath,
  () => {
    isOpenDashboard.value = false
    isOpenBonuses.value = false
  },
)
</script>

<style scoped>
.user-actions {
  position: relative;
  display: flex;
  gap: var(--spacing-300);
}

.notification-activator {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notifications-counter {
  position: absolute;
  top: -6px;
  right: -6px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #fe5e01;
  border: 2px solid var(--background-base);
  border-radius: var(--border-radius-full);
}

.tooltip-wrapper {
  display: flex;
  align-items: center;

  margin-right: calc(var(--spacing-150) * -1);

  background-color: var(--background-primary);
  border-radius: var(--border-radius-125) 0 0 var(--border-radius-125);
}

.tooltip-balance {
  cursor: pointer;
  user-select: none;
  width: 100%;
  height: 100%;
}

.tooltip-activator {
  cursor: pointer;

  display: flex;
  gap: var(--spacing-100);
  align-items: center;

  padding: 3px var(--spacing-125); /* stylelint-disable-line */
  padding-right: var(--spacing-250);

  .text {
    font: var(--desktop-text-sm-semibold);
  }

  .icon {
    color: var(--palette-light-600);
    transition: transform 0.2s;

    &.active {
      transform: rotate(-180deg);
    }
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
  }

  .bonus-balance {
    margin-top: -2px; /* stylelint-disable-line */
  }
}

.deposit-button-wrapper {
  position: relative;
  display: flex;

  .chevron-wrapper {
    position: absolute;
    top: 0;
    right: -20px;
  }
}

.refill-button {
  margin-left: calc(var(--spacing-250) * -1);
}

.tooltip-content {
  min-width: 352px;
  padding: var(--spacing-050);
  background-color: var(--background-primary);
  border-radius: var(--border-radius-100);
}

.flex-wrapper {
  display: flex;
  gap: var(--spacing-100);
}
</style>
